<template>
  <div>
    <CRow>
      <CCol sm="12">
        <div style="float: right; font-size: 12px;">
          <table>
            <tr>
              <td>
                  <label style="margin-bottom: 6px;"> Fecha:</label>
                  <v-date-picker                    
                    v-model="range"    
                    class="date-picker"
                    locale="es-MX" size="sm"          
                    mode="range"
                    style="width: 210px; font-size: 12px !important;"
                  />  
              </td>
              <td>
                <label>Dirección: </label>
                <CSelect
                    :value.sync="direction_code"
                    :options="directionsOptions"
                    invalidFeedback="Este campo es requerido."
                    style="width: 210px; font-size: 12px !important; margin-top: -5px; margin-bottom: 0"
                    @change="changeFilters"
                  />
              </td>
              <td>
                <label>Status: </label>
                <select v-model="stage_code" class="form-control" style="width: 210px; font-size: 12px; margin-top: -5px;" @change="changeFilters">
                <option value="">Todos</option>
                  <option :value="stage.code" v-for="stage in stages">{{stage.name}}</option>
                </select>
              </td>
            </tr>
          </table>
        </div>
        <br /><br /><br />
        <div id="map" :style="'border: 1px solid; width: 100%; height: '+(height - 360)+'px;'"></div>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import tickets from '../../services/tickets';
import account_ws from '../../services/account';
import store from '../../store'
import { Loader } from '@googlemaps/js-api-loader';
import { DatePicker } from 'v-calendar'
import moment from 'moment';
import moment_timezone from 'moment-timezone';

moment.tz.setDefault('America/Mexico_city');

export default {
  name: 'TicketsMap',
  components: { 'v-date-picker': DatePicker },
  data: function () {
		return {
            latitude: 0,
            longitude: 0,
            height: 1000,
            account: false,
            tickets: [],
            direction_code: '',
            directions: [],
            directionsOptions: [],
            stage_code: '',
            stages: [],
            range: {
              start: new moment().startOf('year').toDate(),
              end: new moment().endOf("year").toDate(),
            }
        }
  },
  mounted: async function() {
    this.loading();

    this.latitude = localStorage.getItem('latitude')*1;
    this.longitude = localStorage.getItem('longitude')*1;

    let response = await tickets.get();

    if(response.type == "success"){
      this.tickets = response.data;
    }

    let response2 = await tickets.getDirections();

    if(response2.type == "success"){
      this.directions = response2.data;

      this.directionsOptions = await this.$parseSelectOptionsOrdered(this.directions, "id", "name", [{"label": "Todas","value":""}]);
    }

    let response3 = await tickets.getOpenStages();

    if(response3.type == "success"){
      this.stages = response3.data;
    }

    this.account = JSON.parse(localStorage.getItem("account"));
    this.latitude = this.account.latitude*1;
    this.longitude = this.account.longitude*1;

    this.loadMap();
    this.loaded();
  },
  methods: {
    async refreshItems (items){
      this.loading();
      this.users = await items;
      this.loaded();
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    },
    loadMap(){
      const apiOptions = {
        apiKey: "AIzaSyBjRSxI62T4CTN4Jl_R-1PJoIl2cAo2pAA"
      }

      this.height = window.screen.height;

      const loader = new Loader(apiOptions);

      loader.load().then(() => {
        const map = this.displayMap();
      });
    },
    displayMap: async function() {
			const mapOptions = {
				center: { lat: this.latitude, lng: this.longitude },
				zoom: 15,
        mapTypeId: 'satellite',
        mapId: "434879d77db0d24b"
			};

      this.loading();

      const mapDiv = document.getElementById('map');
			const map = new google.maps.Map(mapDiv, mapOptions);

      this.tickets.forEach(async (ticket) => {
        if(ticket.geolocation){
          const position = ticket.geolocation.split(',');
          this.placeMarker(map, { lat: position[0]*1, lng: position[1]*1 }, ticket);
        }
        else{
          let geocoder = new google.maps.Geocoder();

          let _this = this;

          geocoder.geocode( { 'address': ticket.location+" "+_this.account.name+" Jalisco"}, function(results, status) {
            if (status == 'OK') {
              _this.placeMarker(map, results[0].geometry.location, ticket);
            } else {}
          });
        }
      });

      this.loaded();

			return map;
		},
    placeMarker: async function(map, location, ticket) {
      const { AdvancedMarkerElement, PinElement } = await google.maps.importLibrary("marker");
      const { InfoWindow } = await google.maps.importLibrary("maps");

      let content = 
      '<div style="width: 200px;">'+
      '<b>No. Reporte: </b>'+ticket.number+'<hr />'+
      '<b>Fecha: </b>'+ticket.date+'<br />'+
      '<br /<b>Descripción: </b>'+ticket.description+'<br />'+
      '<br /><b>Dirección: </b>'+ticket.direction+'<br />'+
      (ticket.manager_id > 0 && ticket.manager ? '<b>Responsable: </b>'+ticket.manager.name+'' : '')+
      (ticket.location ? '<br /><br /><b>Domicilio: </b>'+ticket.location+'' : '')+
      (ticket.stage_id > 0 && ticket.stage ? '<hr /><b>Estado: </b>'+ticket.stage+'<hr />' : '')+
      '<div style="width: 100%; text-align: center;"><a href="/#/tickets/'+ticket.code+'/view">Ver Reporte</a><br /></div>'+
      '</div>'+
      ''
      ;

      const infowindow = new google.maps.InfoWindow({
        content: content,
        headerDisabled: true
      });

      infowindow.close();

      const pinBackground = await this.$pin(ticket.color, ticket.status);

      const marker = new AdvancedMarkerElement({
        map,
        position: location,
        content: pinBackground.element,
        gmpClickable: true,
      });

      marker.addListener("click", ({ domEvent, latLng }) => {
        if(infowindow.isOpen){
          infowindow.close();
        }
        else{
          infowindow.open({
            anchor: marker,
            map,
          });
        }
      });
    },
    changeFilters: async function(){
      this.loading();
      let dates = [moment(this.range.start).format("YYYY-MM-DD"),moment(this.range.end).format("YYYY-MM-DD")];

      let response = await tickets.getFilteredTickets(dates, this.direction_code, this.stage_code);

      if(response.type == "success"){
        this.tickets = response.data;
        this.loadMap();
      }

      this.loaded();
    },
  },
  watch: {
    range: async function(){
      await this.changeFilters();
    }
  },
}
</script>
<style>
  .date-picker input{
    height: 32px;
    margin-top: -3px;
    font-size: 12px !important;
  }

  select{
    height: 32px;
    font-size: 12px !important;
    margin-bottom: 0px;
  }
</style>